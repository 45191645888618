<template>
  <div class="exhibitions">
    <div class="page-info">
      <h1 class="header">Exhibitions</h1>
      <h1 id="page-title"> {{ exhibitionArts.exhibitTitle }} </h1>
      <div class="exhibit-info"> {{ exhibitionArts.exhibitLocation }} </div>
      <h1 class="secondary-info"> {{ exhibitionArts.exhibitDescription }} </h1>      
      <div class="exhibit-info"> {{ exhibitionArts.exhibitDate }} </div>
      <div class="exhibit-info"> {{ exhibitionArts.exhibitNotes }} </div>
      <div v-if="displayInfo" ref="sideInfo" id="side-info-overlay">
        <ExhibitionResultAdditionalInfo v-on:updateDisplay="displayInfo=false" :name="this.name" :image="this.image" :type="this.type" :materials="this.materials" :year="this.year" :size="this.size" :location="this.location"/>
      </div>
    </div>
    <div class="catalog">
      <div class="catalog-window">
        <div ref="scrollbar" id="scrollbar">
          <ul ref="catalogResultList" id="catalog-result-list">
            <li class="exhibition-list-item" v-for="(art, index) in exhibitionArts.exhibitions" :key="art.imageFile" @click='displayInfo=false; displayInfo=true; openInfo(art.nameOfWork, art.imageFileRefCompressed, art.typeOfWork, art.materials, art.size, art.yearCreated, art.location); clickAction(index)'> 
              <ExhibitionResult :image="art.imageFileRefCompressed" :name = "art.nameOfWork"/>
            </li>
          </ul>
        </div>
      </div>
      <transition name="slide">
        <div v-if="displayOverlay" ref="additionalInfoOverlay" id="additional-info-overlay">
          <ExhibitionResultAdditionalInfo v-on:updateDisplay="displayOverlay=false; clickStateCount=0; clickState=[]" :image="image"/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API from '../services/api'
import ExhibitionResult from '../components/ExhibitionResult.vue'
import ExhibitionResultAdditionalInfo from '../components/ExhibitionResultAdditionalInfo.vue';
axios.defaults.headers.common["Content-Type"] = "application/json";


export default {
  components: {
    ExhibitionResult,
    ExhibitionResultAdditionalInfo
  },
  data() {
    return {
      name: '',
      exhibition: '',
      name: '',
      size: '',
      number: '',
      image: '',
      errors: '',
      artworks: '',
      exhibitLocation: '',
      exhibitDetails: '',
      displayOverlay: false,
      displayInfo: false,
      clickStateCount: 0,
      clickState: []
    }
  },
  mounted() {
   this.getExhibitionData();  
  },
  watch: {
    $route(to, from) {
      this.getExhibitionData();
    }
  },
  computed:{
      exhibitionArts : function() {
        this.displayInfo = false
        let art = this.$store.state.exhibitions;
        console.log(art);
        return art;
    }
  },
  methods:{
    async getExhibitionData(){
      API.exhibitionData(this.$route.params.id).then(data => {
        //Method only returns one exhibit so we can use the index
        this.$store.commit('updateExhibitions', data[0]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    },
    openInfo(name, image, type, materials, size, year, location){

        this.name = name,
        this.image= image,
        this.type= type,
        this.materials = materials,
        this.size = size,
        this.year = year,
        this.location = location
    },
    clickAction(key){
      //Checks if the user is clicking a new image
      if(this.clickState[key] === undefined){
        //Reset the array if user clicks a new image, removes existing clicks on others
        this.clickStateCount = 0;
        this.clickState = [];
      }
      this.clickStateCount++;

      this.clickState[key] = this.clickStateCount;

      //If clicks on this particular image is 2 then load overlay
      if(this.clickState[key] == 2){
        this.displayOverlay= true; 
        this.displayInfo=false;
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.exhibitions{
  margin-top: 140px;
  margin-right: 50px;
  margin-left: 25px;
}

.catalog{
  overflow: hidden;
}
.page-info{
  width: 25%;
  position: absolute;
}

.page-info h1{
  margin-left: 45px;
  margin-top: 55px;
  text-align: left;
  color: #801818;
  font-size: 28px;
  margin-bottom: 0px;
}

.header{
  font-size: 30px !important;
}

.secondary-info{
  margin-top: 30px !important;
}

.exhibit-info{
  color: black;
  font-size: 28px;
  margin-left: 45px;
  text-align: left;
  font-weight: normal;
}

#catalog-result-list{
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
  margin-bottom: 25px;
  padding-left: 0px;
  margin-bottom: 200px;
}
ul{
  list-style-type: none;
}

.catalog-window{
  height: 100%;
  position: relative;
  z-index: 1;
  margin-left: 25%;
  display: flex;
  justify-content: flex-end;
}

.exhibition-list-item {
  width: 25%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.additional-info{
  display: none;
}
.side-info {
  position: absolute;
  top: 50px;
  text-align: left;
  font-size: 30px;
  margin-left: 38px;
}
.side-info h1{
  font-weight: normal;
  font-size: 30px;
  color: #801818;
  margin-bottom: 0;
}

.info-block{
  padding: 20px 20px 0px 20px;
  display: flex;
}

.info-base{
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 0 25px;
  box-sizing: border-box;
}

.year p{
  text-align: left;
}

#additional-info-overlay{
  visibility: visible;
  position: absolute;
  top: 0%;
  height: 100%;
  left: 95px;
  width: 100%;
  z-index: 15;
}
#additional-info-overlay .image-info{
  display: none;
}

#scrollbar {
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 25px;
  width: 100%;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  position: relative;
  z-index: 1;
}

#shadow-overlay{
  display: block;
  width: 100%;
  height: 100%;
  background-color: #f4f5f6ff;
  top: 0%;
  position: absolute;
  opacity: 0.5;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  transition: 1s all ease;
}

@media (max-width: 1700px) {
  .exhibition-list-item {
    width: 25%;
  }
}

@media(max-height: 750px) {
  #page-title, .exhibit-info, .secondary-info{
    font-size: 26px;
  }

}

@media (max-width: 1100px) {
  .exhibition-list-item {
    width: 40%;
  }
  #catalog-result-list{
    justify-content: center;
  }
  .catalog-window{
    margin-left: 35%;
  }
}

@media (max-width: 1250px) {
  #shadow-overlay{
    left: -75px;
  }
  #additional-info-overlay{
    left: -75px;
  }
  .catalog-window{
    margin-left: 30%;
  }
  .header{
    padding-top: 10px;
  }
  .page-info{
    h1{
      font-size: 20px;
    }
  }
  .exhibit-info{
    font-size: 20px;
  }
}

@media (max-width: 870px) {
  .exhibition-list-item:after {
    display: none;
  }

  .exhibition-list-item {
    width: 60%;
  }

  #scrollbar{
    width: 100%;
  }

  .page-info{
    width: 35%;
  }

  #catalog-result-list{
    justify-content: center;
  }
}
@media (max-width: 550px) {
  .exhibitions{
    display: block;
    margin-right: 5px;
    margin-left: 5px;
  }
  .exhibit-info {
    margin: 0;
  }
  .page-info{
    width: 100%;
    position: relative;
    h1 {
      margin: 0;
    }
    #page-title{
      margin: 0;
      padding: 0;
    }
  }
  .catalog{
    margin-top: 0px;
    width: 100%;
    margin-left: 0;
  }
  .exhibition-list-item{
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    
  }
  .catalog-window {
    margin-left: 0px;
  }
  .page-info{
    width: 100%;
    h1{
      font-size: 25px;
    }
  }

  .exhibit-info{
    font-size: 20px;
  }

    .exhibition-list-item:after{
    content: "";
    display: block;
    width: 80px;
    height: 80px;
  }

  .exhibition-list-item:first-child:after {
    content: "";
    display: block;
    width: 80px;
    height: 80px;
    background: url('../assets/expand_more.svg') no-repeat 0% 0%;
    background-size: cover;
    margin: -20px 42%;
    /* opacity: 0.3; */
  }
}
</style>