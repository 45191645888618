import axios from "axios";
import AxiosRequestConfig from "axios";
axios.defaults.headers.common["Content-Type"] = "application/json";

function request(urlPath, config = AxiosRequestConfig) {
    config = {
        params: {
            _format: "json"
        },
        ...config
    }
    return axios({
        url: urlPath,
        method: 'GET',
        ...config
    });
}

export default { 
    async getBiography() {
        const body = await request(process.env.VUE_APP_SERVER_BASE_URL + '/api/biography', "GET")
        return body?.data
    },
    async lostWorks() {
        const body = await request(process.env.VUE_APP_SERVER_BASE_URL + process.env.VUE_APP_LOST_WORKS, "GET")
        return body?.data
    },
    async exhibitionData(id) {
        const body = await request(process.env.VUE_APP_SERVER_BASE_URL + process.env.VUE_APP_EXHIBIT + id, "GET")
        return body?.data
    },
    async catalogData() {
        const body = await request(process.env.VUE_APP_SERVER_BASE_URL + process.env.VUE_APP_ART_DATABASE_NAME, "GET")
        return body?.data
    },
    async getExhibits() {
        const body = await request(process.env.VUE_APP_SERVER_BASE_URL + process.env.VUE_APP_EXHIBITS_MENU, "GET")
        return body?.data
    },
}

