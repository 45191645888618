<template>
    <div class="catalog-result">
        <div class="catalog-frame">
          <div class="image-frame">
            <div id="image" class="image"> 
                <img :src="image" :alt="altText">
              </div>
          </div>
        </div>  
      </div>
</template>

<script>
import ExhibitionResultAdditionalInfo from '../components/ExhibitionResultAdditionalInfo.vue';

export default {
  name: "Exhibition-Results",
  components: {
    ExhibitionResultAdditionalInfo
  },
    data: () => ({
      catalogResults: '',
      altText: ''
  }),
  props:[
       
      'name',
      'image',
      'type',
      'materials',
      'entireRef'

  ],
  mounted(){
    this.init() 
  },
  methods: {
    init(){

      this.altText = "Folke Heybroek " + this.name;

      var elements = document.getElementsByClassName("image");
      for(var i=0; i < elements.length; i++){
        elements[i].addEventListener("click", function(){
          var visible = document.getElementsByClassName("show");
          for(var i=0; i < visible.length; i++){
            visible[i].classList.remove("show");
          }
          this.classList.add("show");
          
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .catalog-frame{
    width: inherit;
    height: inherit;
    padding: 25px 25px 5px 25px;    
  }

  .catalog-result{
    display: flex;
    justify-content: center;
  }

  .image-frame{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .image-frame img{
    height: auto;
    width: 100%;
    @media (max-width: 1350px) {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 550px) {
      width: 100%;
      height: auto;
    }
  }
  .image-frame img:hover{
    border: 5px solid black;
  }


  .enlarge-icon{
    display: none;
  }

  .show{
    .enlarge-icon{
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 10px;
      :hover{
        border: none !important;
        margin: 0px;
      }
      img{
        width: 30px;
        height: 30px;
      }
    }
  }



  .info-outline{
    border: 1px #d0d3d8ff solid;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .view-more-button{
    padding: 5px 0px 5px 10px;
    height: 25px;
    text-align: right;
    border: none;
    background-color: white; 
    color: #E51A29;
  }

  h3{
    text-align: center;
  }

  .info-block {
    text-align: left;
  }
  .info-block ul {
    padding: 0;
    line-height: 1.6;
  }

  .info-block .label {
    color: #7a7c81;
    display: inline-block;
    min-width: 150px;;
  }

  .info-block .art-info {
    color: black;
  }

</style>