<template>
  <div class="menu-wrapper">
    <div id="nav" ref="nav">
      <div class="primary menu">
          <div class="menu-list-wrapper">
            <ul>
              <li><router-link to="/biography"  @click.native='displaySubMenu= false; displayExhibitMenu= false; close_menu()'>Biography</router-link></li>
              <li><router-link to="/artworks"  @click.native='displaySubMenu= true; displayExhibitMenu= false; cleanExhibit()' id="artworks" ref="artworks">Artworks</router-link> </li>
              <li><router-link to="/exhibitions" @click.native='displayExhibitMenu= true; displaySubMenu= false; cleanArtwork()' id="exhibits" ref="exhibits">Exhibitions</router-link> </li>
              <li><router-link to="/lostworks" @click.native='displaySubMenu= false; displayExhibitMenu= false; close_menu();'>Lost works</router-link> </li>
              <li><router-link to="/contact" @click.native='displaySubMenu= false; displayExhibitMenu= false; close_menu();'>Contact</router-link> </li>
            </ul>
          </div>
      </div>
      <div v-if="displaySubMenu" class="secondary menu">
        <ul>
          <li v-for="catalogDate in catalogDates" :key="catalogDate.name">
              <router-link
                :to="`/artworks/catalog/${catalogDate.name}`"
                @click.native='close_menu()'
                id ="catalogDate.name"
                class="filter artworks-menu">
                <div class="menu-country-year">
                  <div class="country">{{ catalogDate.country }}</div>
                  <div v-if="catalogDate.startYear == catalogDate.endYear" class="menu-year"> 
                    {{ catalogDate.startYear }}
                  </div>
                  <div v-if="catalogDate.startYear !== catalogDate.endYear" class="menu-year"> 
                    {{ catalogDate.startYear }} - {{ catalogDate.endYear }} 
                  </div>
                </div>
              </router-link>
          </li>
        </ul>
      </div>
      <div v-if="displayExhibitMenu" class="secondary menu" >
        <ul id="secondary-menu">
          <li v-for="exhibitMenuItem in exhibitMenuItems" :key="exhibitMenuItem.exhibitTitle">
              <router-link 
              :to="`/exhibitions/exhibition/${exhibitMenuItem.exhibitID}`"
              @click.native='close_menu();'  
              class="filter exhibits-menu">
                <div class="menu-details">
                  <div class="menu-title">{{ exhibitMenuItem.exhibitTitle }}</div>
                  <div class="menu-year"> {{ exhibitMenuItem.exhibitDate }}</div>
                </div>
              </router-link>
          </li>
        </ul>
      </div>
    </div> 
  </div>
</template>


<script>
import API from '../services/api'

export default{
  data: () => ({
      message: '',
      title:'',
      exhibitDate:'',
      exhibitID:'',
      exhibitDates: '',
      displaySubMenu: false,
      displayExhibitMenu: false,
      currentRoute: '',
      catalogDates:[
        {name: 'holland1936', country:'Holland', startYear:'1936', endYear:'1938'},
        {name: 'sicily1939', country:'Sicily', startYear:'1939', endYear:'1939'},
        {name: 'sweden1940', country:'Sweden', startYear:'1940', endYear:'1948'},
        {name: 'france1949', country:'France', startYear:'1949', endYear:'1949'},
        {name: 'sweden1950', country:'Sweden', startYear:'1950', endYear:'1967'},
        {name: 'holland1969', country:'Holland', startYear:'1969', endYear:'1983'}
      ],
      exhibitMenuItems:[],
      reset: false,

    }),
  mounted() {
   this.init()   
  },
  methods: {
    init(){
      this.getExhibitTitles();
    },
    close_menu(){
      this.$emit('updateMenu');
    },
    async getExhibitTitles(){
       API.getExhibits().then(data => {
        var exhibitData = data;
        for(var i=0; i < exhibitData.length; i++){
          this.exhibitMenuItems.push(exhibitData[i]);
        }
      })
    },
    cleanExhibit(){
      var exhibitMenu = document.getElementById("exhibits");
      exhibitMenu.classList.remove('menu-active-trail');
    },
    cleanArtwork(){
      var artworkMenu = document.getElementById("artworks");
      artworkMenu.classList.remove('menu-active-trail');
    }
  }
}
</script>


<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.menu-wrapper {
  position: absolute;
  display: flex;
  top:0;
  left: 0;
  z-index: 2;
}

#nav {
  padding-left: 0px;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  padding-top: 110px;
}
.menu-list-wrapper {
  
  display: flex;
  justify-content: center;
    pointer-events: all;
    height: 100%;

}

.menu.primary{
  padding-left: 75px;
  padding-right: 75px;
}
@media (max-width: 550px) {
  .menu.primary{
    padding-left: 15px;
    padding-right: 15px;
  }
}

.menu.secondary{
  padding-right: 50px;
}

.menu.primary , .menu.secondary {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top: 25px;
}



.menu li{
  list-style-type: none;
  text-align: left;
    pointer-events: all;

}

.menu a {
  cursor: pointer;
  font-weight: 600;
  color:black;
  text-decoration: none;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 20px 10px;
}
.secondary a {
  padding: 20px 15px 20px 10px;
} 

.menu a.router-link-exact-active{
  font-weight: bold;
  color: #801818; 
}

.menu-active-trail{
  font-weight: bold !important;
  color: #801818 !important; 
}

.menu ul {
  padding: 0;
  margin: 0;
}

.secondary a:hover {
  color: #801818;
}

.secondary{
  a{
    font-size: 22px;
    padding-bottom: 10px;
  }
}

.menu a {
  flex-wrap: wrap;
}
.menu a .country .menu-title {
  font-weight: bold;
  width: 100%;
}
.country {
  font-size: 20px;
}
.menu a .menu-year {
  font-size: 20px;
  font-weight: lighter;
  width: 100%;
}

@media(max-height: 750px) {
  .menu.primary , .menu.secondary {
    margin-top: 15px;
  }
}

@media (max-width: 550px) {
  .menu.primary, .menu.secondary{
    height: 100%;
    padding-top: 0px;
        margin-top: 0;
  }
  .menu.primary{
    padding-left: 10px;
  }
  .menu.primary li {
  margin-bottom: 0;
  }
  .menu a{
    font-size: 18px;
    .menu-year{
      font-size: 14px;
    }
  }
}

</style>