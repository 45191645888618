<template>
  <div class="overlay"> 
    <div class="additional-info-window">
      <button id="close-more-button" type="button" @click='closeInfo()'>
        <img alt="close" src="../assets/close_black.svg">
      </button>
      <div class="artwork-info">
        <div class="compressed_image">
          <img :src="image">
        </div>
        <div class="image-info">
          <h1> {{ name }}</h1> 
          <div class="info-block-detail">
              <ul>
                <li><span class="art-info">{{ materials }}</span></li>
                <li><span class="art-info">{{ size }}</span></li>
                <li><span class="art-info">{{ year }}</span></li>
                <li><span class="art-info">{{ location }}</span></li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Exhibition-Results-additional",
  mounted() {   
    },
    data: () => ({
  }),
  props:[
      'name',
      'type',
      'materials',
      'year',
      'image',
      'size',
      'location'
  ],
  computed:{
    },
  methods:{
    closeInfo(){
      var title = document.getElementById('title');
      this.$emit('updateDisplay');
    }
  },
}

</script>

<style lang="scss" scoped>

.overlay{
  height: 100%;
}

.additional-info-window{
  height: 100%;
  width: 100%;
  display:block;
  background-color: #37373c;
  padding: 10px 10px 10px 10px;
  border-right: 5px solid #8996abff;
  position: fixed;
  top: 65%;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

#additional-info-overlay{
  position: relative;
  .overlay{
    >.additional-info-window{
      top: 0%;
    }
  }
}

#side-info-overlay .additional-info-window{
  height: auto;
  width: 25%;
  border-right: none;
  background-color: transparent;
}
.info-block-detail {
  text-align: left;
}
.info-block-detail ul {
  padding: 0;
  line-height: 1.6;
  margin: 0;
}
.info-block-detail li {
  font-size: 25px;
  display: flex;
}

.info-block-detail .label {
  color: #7a7c81;
  display: inline-block;
  min-width: 80px;
}

.info-block-detail .art-info{
  color: black;
  display: inline-block;
  font-size: 25px;
}

#additional-info-overlay .artwork-info{
  display: flex;
  justify-content: center;
}

.image-info{
  margin-left: 70px;
  margin-top: 0px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: normal;
}

.image-info h1{
  color: #801818;
  text-align: left;
  margin-bottom: 0px;
  font-size: 28px;
}

.image-info .year{
  color: black;
  display: block;
}

#close-more-button{
  left: 45%;
  position: relative;
  top: 0%;
  border: none;
  margin-top: 25px;
  background: transparent;
  cursor: pointer;
  img{
    width: 75px;
    height: auto;
    background: transparent;
  }
}
#side-info-overlay #close-more-button {
  display: none;
}
#side-info-overlay .compressed_image {
  display: none;
}
.compressed_image {
  width: 60%;
  margin-bottom: 50px;
}
.compressed_image img{
  height:auto;
  width: 100%;
}

.logo-icon img{
  width: 40px;
  height: 40px;
}
@media(max-height: 750px) {
  .image-info h1{
    font-size: 26px;
  }

  .info-block-detail li {
    font-size: 24px;
  }

}
@media (max-width: 1200px) {
  #close-more-button {
    margin-right: 35px;
  }
  .compressed_image {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
  #close-more-button img{
    width: 60px;
  }
  .artwork-info {
    margin-top: 25px;
    font-size: 25px;
  }
  #additional-info-overlay .artwork-info{
    display: flex;
    justify-content: initial;
  }

  .image-info{
    margin-left: 65px;
    h1{
      font-size: 22px;
    }
    .art-info{
      font-size: 20px !important;
    }
    .art-info-notes{
      font-size: 16px;
    }
  }
}

@media (max-width: 870px) {
  .additional-info-window{
    top: 60%;
  }
}

@media (max-width: 600px) {
  .compressed_image {
    width: 80%;
    margin-left: 10px;
  }
  .image-info{
    margin-left: 25px;
  }
  #close-more-button img{
    width: 40px;
  }
  #side-info-overlay .additional-info-window{
    height: 100%;
    width: 95%;
    background-color: white;
    z-index: 100;
    top: 75%;
  }
  .artwork-info {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .exhibitions .additional-info-window {
    width: 100% !important;
    margin-left: -35px !important;
    position: absolute !important;
    top: 100% !important;
  }
}
</style>