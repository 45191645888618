<template>
  <div class="home" :style="style()">
    

  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {

  },
  data: () => ({
    exampleTitle: "example title"
  }),
  methods: {
    style() {
      return {
        // backgroundImage: 'url(' + require('@/assets/down-to-the-lake.jpg') + ')'
      }
    }
  }
}
</script>

<style lang="css">
.home {
  /* width: 100%; */
  height: 100%;
  background-image: url("../assets/Homepage.jpg");
  background-size: cover;
  background-position: 50%;
  position:fixed;
  left: 0;
  bottom:0;
  right: 0;
  border-bottom: 75px solid white;
  border-left: 75px solid white;
  border-right: 75px solid white;
}

@media (max-width: 550px){
  .home {
    border-bottom: 15px solid white;
    border-left: 15px solid white;
    border-right: 15px solid white;
  }
}

.ig-home-button {
  display: initial;
}
.map-src {
  margin-top: 150px;
  height: 850px;
  width: auto;
}
.image-1 {
  cursor: pointer;
  position: static;
  z-index: 100;
}
</style>
