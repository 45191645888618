<template>
  <div id="app">
    <div class="logo">
      <div v-show="displayMenu">
      <Navigation class="outside-click-exclude" v-on:updateMenu="closeMenu()"/>
    </div>
    <div class="menu-icon" id="display-menu" v-outside-click="{
      exclude: ['outside-click-exclude', 'router-link-active' ],
      handler: closeMenu
    }">
      <button id="menu-button" type="button" ref="menu_button" class="menu-button collapsible" @click="displayMenu = !displayMenu; highlightParent()">
        <img v-if="displayMenu" src="./assets/menu.svg" />
        <img v-else src="./assets/menu.svg" />
      </button>
    </div>
        <div class="logo-wrapper" :style="{background: 'url(' + require('./assets/logo_bg.png') + ') no-repeat 0 0 transparent', backgroundSize: 'contain'}">
          <a href="/">
            <img src="./assets/logo-newcol.svg" />
          </a>
        </div>
        <!-- <div class="ig-home-button">
          <a  href="https://www.instagram.com/">
            <img src="./assets/instagram-svgrepo-com.svg">
          </a>
        </div> -->
    </div>
    <router-view />
    
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import Navigation from "@/components/Navigation.vue";
import OutsideClick from './directives/OutsideClick'

Vue.directive('outside-click', OutsideClick)

export default {
  name: "app",
  components: {
    Navigation,
  },
  data: () => ({
      displayMenu: false,
  }),
  computed:{
      
  },
  methods: {
    button_controls() {
      var active = this.$refs.menu_button;
      var title = document.getElementById("title");

      if (active.classList.contains("active-button") == true) {
        title.style.visibility = "visible";
      } 
      else {
        if (title != null) {
          title.style.visibility = "hidden";
        }
      }
    },
    closeMenu(){
      this.displayMenu=false;
    },
    highlightParent(){
      var url = window.location.href;
      var artworksMenu = document.getElementById("artworks");
      var exhibitMenu = document.getElementById("exhibits");
      if(url.includes('catalog')){
        artworksMenu.classList.add('menu-active-trail');
        exhibitMenu.classList.remove('menu-active-trail');
      }else if(url.includes('exhibitions')){
        exhibitMenu.classList.add('menu-active-trail');
        artworksMenu.classList.remove('menu-active-trail');

        if(document.getElementById("page-title")){
          var pageTitle = document.getElementById("page-title");
          var menu = document.getElementById("secondary-menu").children;
          for(var i=0; i < menu.length; i++){
            if(!menu[i].innerText.includes(pageTitle.innerText)){
              menu[i].firstChild.classList.remove('router-link-exact-active');
              menu[i].firstChild.classList.remove('router-link-active'); 
            }
          }
        }

      }else{
        artworksMenu.classList.remove('menu-active-trail');
        exhibitMenu.classList.remove('menu-active-trail');
      }
    },

  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');
#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-weight: 600;
}
p, h1, h2, h3, h4, li, a {
  font-family: 'Raleway', sans-serif;
}
html {
  font-size: 16px;
}
.lostworks p {
  margin-top: 0px;
}

#nav {
  padding-left: 0px;
  visibility: visible;
}

#menu-button {
  position: relative;
  z-index: 2;
  background-color: white;
}
#menu-button:hover {
  cursor: pointer;
}
.ig-wrapper {
  position: relative;
  margin-top: 35px;
  margin-right: 45px;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
}
.menu-icon button {
  padding: 0px;
  border: none;
  width: 100%;
}
.menu-icon img {
  width: 50px !important;
  height: 50px !important;
}

.menu-icon {
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  left: 75px;
  top: 40%;
  z-index: 2;
  // margin-left: 50px;
}
.logo {
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  padding: 20px 0px;
  background: white;
  display: flex;
  justify-content: center;
  .ig-home-button {
    position: absolute;
    top: 40%;
    right: 35px;
    height: 45px;
    width: 45px;
    margin-right: 50px;
    a {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.logo a {
  background: white;
  position: relative;
  z-index: 5;
  height: 100%;
  display: block;
}

.logo-wrapper {
  width: 180px;
  background-size: contain;
}
.logo img {
  width: 100%;
  height: auto;
}

@media(max-height: 750px) {
  .ig-home-button {
    height: 35px;
    width: 35px;
  }

  .menu-icon img {
    width: 45px !important;
    height: 45px !important;
  }

  .logo {
    padding: 10px 0px;
  }

  .logo-wrapper {
    width: 200px;
  }

}

@media (max-width: 550px) {
  .logo{
    position: fixed;
    width: 100%;
    justify-content: center;
  }

  .logo img {
    width: 100%;
  }

  .logo-wrapper{
    width: 100px;
  }

  .logo >a {
    background: white;
    position: relative;
    z-index: 1;
    width: 100%;
  }
  .menu-icon{
    #menu-button {
    margin-top: 0px;
      img {
        width: 35px !important;
        height: 35px !important;
        display: flex;
      }
    }
  }
  #nav {
    padding: 0;
    padding-top: 80px !important;
  }
  .logo {
  .ig-home-button {
    width: 35px;
    position: absolute;
    right: 35px;
    top: 35%;
    height: 35px;
    margin-right: 0;
  }
}
}
@media (max-width: 1190px) {
  .menu-wrapper {
      overflow: scroll;
    }
    
    .menu.secondary ul {
      margin: 0;
    }
}
@media (max-width: 550px) {
  .menu-icon {
  left: 15px;
  }
}
@media (max-width: 440px) {
  .menu.primary li {
    margin: 0
  }
  .menu a {
    padding: 5px 5px 20px 0px;
  }
}
</style>