<template>
  <div class="catalog-listing">
    <div class="catalog-info">
      <div class="page-title">
        <h1>Artworks</h1>
      </div>
      <div id="title" ref='title'>
        <div class='country-content'>
          <h1> {{ country }} </h1>
        </div>
        <div class='year-content'>
          <h1 v-if="endYear == startYear">
            {{ startYear }}</h1>
          <h1 v-if="endYear != startYear">
            {{ startYear }} ― {{ endYear }}</h1>
        </div>
      </div>
    </div>
    <div v-if="displayInfo" ref="sideInfo" id="side-info-overlay">
      <CatalogResultAdditionalInfo v-on:updateDisplay="displayInfo = false" :name="this.name" :materials="this.materials"
        :year="this.year" :size="this.size" :location="this.location" :notes="this.notes" />
    </div>

    <div class="catalog">
      <div v-if="displayLoading" class="loading"> Loading... </div>
      <div class="catalog-window">
        <div ref="scrollbar" id="scrollbar">
          <ul ref="catalogResultList" id="catalog-result-list">
            <li class="catalog-list-item" v-for="(catalogResult, index) in catalogResults" :key="catalogResult.image"
              @click='displayIcon = true; displayInfo = false; displayInfo = true; openInfo(catalogResult.nameOfWork, catalogResult.imageFileRefCompressed, catalogResult.typeOfWork, catalogResult.materials, catalogResult.size, catalogResult.yearCreated, catalogResult.location, catalogResult.notes); clickAction(index)'>
              <CatalogResult :image="catalogResult.imageFileRefThumbnail" :name="catalogResult.nameOfWork" />
            </li>
          </ul>
        </div>
      </div>
      <div v-if="displayOverlay" ref="shadowOverlay" id="shadow-overlay"></div>
      <transition name="slide">
        <div v-if="displayOverlay" ref="additionalInfoOverlay" id="additional-info-overlay">
          <CatalogResultAdditionalInfo v-on:updateDisplay="displayOverlay = false; clickStateCount = 0; clickState = []"
            :image="this.image" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import CatalogResult from '@/components/CatalogResult.vue'
import CatalogResultAdditionalInfo from '../components/CatalogResultAdditionalInfo.vue';
const axios = require('axios');
import API from '../services/api'


export default {
  name: "Catalog",
  components: {
    CatalogResult,
    CatalogResultAdditionalInfo
  },
  mounted() {
    this.init()
  },
  data: () => ({
    name: '',
    image: '',
    type: '',
    materials: '',
    size: '',
    year: '',
    displayOverlay: false,
    displayInfo: false,
    displayLoading: false,
    clickStateCount: 0,
    clickState: []

  }),
  props: {
    'country': String,
    'startYear': String,
    'endYear': String,
    'reset': Boolean,
  },
  computed: {
    catalogResults: function () {
      this.displayInfo = false;
      let catalog = this.$store.state.catalog;
      let entireOrdering = [];
      let partialOrdering = [];
      let entireKeys = [];
      let unsortedEntireKeys = [];

      for (var i = 0; i < catalog.length; i++) {
        var artwork_year = catalog[i].yearCreated;
        if (artwork_year <= this.endYear && artwork_year >= this.startYear) {
          var artwork_type = catalog[i].entireOrPartialImage;
          if (artwork_type.toLowerCase() === "entire") {
            entireOrdering.push(catalog[i]);
            unsortedEntireKeys.push(catalog[i].entireImageRef);
          } else {
            partialOrdering.push(catalog[i]);
          }
        }
      }

      entireOrdering = entireOrdering.sort(function (a, b) {
        return a.yearCreated - b.yearCreated;
      });

      this.displayLoading = false;
      for (var i = 0; i < partialOrdering.length; i++) {
        if (entireKeys.includes(partialOrdering[i].entireImageRef)) {
          for (var j = 0; j < entireOrdering.length; j++) {
            if (
              partialOrdering[i].entireImageRef === entireOrdering[j].entireImageRef &&
              entireOrdering[j].entireOrPartialImage.toLowerCase() === "entire"
            ) {
              var key = j;
            }
          }
          entireOrdering.splice(key + 1, 0, partialOrdering[i]);
        } else {
          entireOrdering.push(partialOrdering[i]);
        }
      }

      entireOrdering = entireOrdering.sort(function (a, b) {
        return a.entireImageRef.localeCompare(b.entireImageRef);
      });

      return entireOrdering;
    }
  },

  methods: {
    init() {
      this.httpResponse();
    },
    async httpResponse() {
      this.displayLoading = true;
      this.displayInfo = false;
      API.catalogData().then((data) => {
        this.$store.commit('updateCatalog', data)
      })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
    },
    openInfo(name, image, type, materials, size, year, location, notes) {
      this.name = name,
      this.image = image,
      this.type = type,
      this.materials = materials,
      this.size = size,
      this.year = year,
      this.location = location,
      this.notes = notes
    },
    clickAction(key) {
      //Checks if the user is clicking a new image
      if (this.clickState[key] === undefined) {
        //Reset the array if user clicks a new image, removes existing clicks on others
        this.clickStateCount = 0;
        this.clickState = [];
      }
      this.clickStateCount++;

      this.clickState[key] = this.clickStateCount;

      //If clicks on this particular image is 2 then load overlay
      if (this.clickState[key] == 2) {
        this.displayOverlay = true;
        this.displayInfo = false;
      }
    },
    findEntire(artworkName) {
      var resultList = this.$refs.catalogResultList;
      var listItems = resultList.childNodes
      for (var i = 0; i < listItems.length; i++) {
        if (!(listItems[i].innerHTML.includes("view-entire-button")) && listItems[i].innerHTML.includes(artworkName))
          listItems[i].scrollIntoView(false)
      }
    },
  }
}

</script>

<style lang="scss" scoped>
.catalog {
  overflow: hidden;
}

.loading {
  font-size: 30px;
  font-weight: normal;
}

.catalog-listing {
  margin-top: 170px;
  margin-right: 50px;
  margin-left: 50px;
}

.catalog-info {
  width: 35%;
  position: fixed;
  margin-left: 18px;
  margin-top: 0px;
}

#title {
  color: #801818;
  transform-origin: bottom left;
  position: absolute;
  left: 0;
  box-sizing: border-box;
}

.year-content {
  text-align: left;

  h1 {
    font-weight: lighter;
    font-size: 22px;
    margin-top: 0;
    color: #801818;
  }
}

.country-content h1 {
  text-align: left;
  margin-bottom: 0px;
  font-size: 26px;
  padding-bottom: 0px;
}

.page-title h1 {
  font-size: 30px;
  text-align: left;
  color: #801818;
}

#catalog-result-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
  margin-bottom: 25px;
  padding-left: 0px;
  margin-bottom: 200px;
}

ul {
  list-style-type: none;
}

.catalog-window {
  height: 100%;
  margin-left: 25%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}

.catalog-list-item {
  width: 25%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.additional-info {
  display: none;
}

.info-block {
  padding: 20px 20px 0px 20px;
  display: flex;
}

.info-base {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 0 25px;
  box-sizing: border-box;
}

.year p {
  text-align: left;
}

#scrollbar {
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 25px;
  width: 100%;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  position: relative;
  z-index: 1;
}

#additional-info-overlay {
  visibility: visible;
  position: absolute;
  top: 0%;
  height: 100%;
  left: 95px;
  width: 100%;
  z-index: 15;

}

#additional-info-overlay .image-info {
  display: none;
}

#shadow-overlay {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #f4f5f6ff;
  top: 0%;
  position: absolute;
  opacity: 0.5;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  transition: 1s all ease;
}

.image-info {
  h1 {
    font-size: 22px;

  }
}

@media (max-width: 1700px) {
  .catalog-list-item {
    width: 25%;
  }
}

@media (max-width: 1100px) {
  .catalog-list-item {
    width: 40%;
  }

  #catalog-result-list {
    justify-content: center;
  }
}

@media (max-width: 1200px) {
  #title {
    font-size: 14px;
  }

  #shadow-overlay {
    left: -75px;
  }

  #additional-info-overlay {
    left: -75px;
  }

}

@media (max-width: 850px) {
  .loading {
    font-size: 25px;
  }

}

@media (max-width: 550px) {
  .loading {
    margin-top: 50px;
  }

  .catalog-listing {
    margin-right: 5px;
    margin-left: 5px;
  }

  .catalog-info {
    position: relative;
    margin-left: 0;
    width: 100%;

    .page-title {
      h1 {
        margin: 0;
        margin-left: 0 !important;
      }
    }

    #title {
      padding-left: 0;

      .country-content {
        margin-right: 12.5px;

        h1 {
          margin: 0;
        }
      }
    }
  }

  .catalog-list-item {
    width: 70%;
  }

  .catalog-list-item:first-child:after {
    content: "";
    display: block;
    width: 80px;
    height: 80px;
    background: url('../assets/expand_more.svg') no-repeat 0% 0%;
    background-size: cover;
    margin: 0px auto;
  }

  .catalog-result {
    justify-content: center;
  }

}

@media (max-width: 670px) {
  .catalog {
    margin-top: 150px;
  }

  .catalog-list-item:after {
    display: none;
  }

  #scrollbar {
    width: 100%;
  }


}

@media (max-width: 550px) {
  .catalog-listing {
    margin-top: 120px;
  }

  .catalog-window {
    margin-left: 0px;
  }

  .catalog-list-item {
    width: 90%;
  }

  .country-content h1 {
    font-size: 20px;
    margin-right: 15px;
  }

  .year-content h1 {
    font-size: 20px;
  }

  .info-block-detail li {
    font-size: 20px;
  }

  .info-block-detail h1 {
    font-size: 20px;
  }

  .page-title h1 {
    margin-left: 18px;
  }

  #title {
    margin-left: 0px;
    width: 100%;
    padding-left: 25px;
    text-align: center;
    display: flex;
  }

  .page-title {
    margin-bottom: 0px;
  }

  .catalog {
    margin-top: 0px;
  }
}</style>