<template>
  <div class="overlay"> 
    <div class="additional-info-window">
      <button id="close-more-button" type="button" @click='closeInfo()'>
        <img alt="close" src="../assets/close_black.svg">
      </button>
      <div class="artwork-info">
        <div class="compressed_image">
          <img :src="this.image">
        </div>

        <div class="image-info">
          <h1> {{ name }}</h1> 
          <div class="info-block-detail">
              <ul>
                <li><span class="art-info">{{ year }}</span></li>
                <li><span class="art-info">{{ materials }}</span></li>
                <li><span class="art-info">{{ size }}</span></li>
                <li><span class="art-info">{{ location }}</span></li>
                <li><span class="art-info-notes">{{ notes }}</span></li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Catalog-Results-additional",
  mounted() {   
    },
    data: () => ({
  }),
  props:[
      'name',
      'type',
      'materials',
      'year',
      'image',
      'size',
      'location',
      'notes',
  ],
  computed:{
    },
  methods:{
    closeInfo(){
      this.$emit('updateDisplay');
    }
    },
  }

</script>

<style lang="scss" scoped>

.overlay{
  height: 100%;
}
.additional-info-window{
  height: 100%;
  width: 100%;
  display:block;
  background-color: #37373c;
  padding: 10px 10px 10px 10px;
  border-right: 5px solid #8996abff;
  position: fixed;
  top: 40%;
  margin-top: 65px;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

#additional-info-overlay{
  position: relative;
  .overlay{
    >.additional-info-window{
      top: 0%;
      margin-top: 0;
    }
  }
}

#side-info-overlay .additional-info-window{
  height: 45%;
  width: 25%;
  // margin-left: -35px;
  border-right: none;
  background-color: transparent;
}
.add-logo{
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;

}

.add-logo img{
  display: none;
}

  .info-block-detail {
    text-align: left;
  }
  .info-block-detail ul {
    padding: 0;
    line-height: 1.4;
    margin-top: 0;
  }
  .info-block-detail li {
    font-size: 25px;
    display: flex;
  }

  .info-block-detail .label {
    color: #7a7c81;
    display: inline-block;
    min-width: 80px;
  }

  .info-block-detail .art-info {
    color: black;
    display: inline-block;
  }

  .art-info-notes {
    margin-top: 25px;
    color: black;
    display: inline-block;
    font-size: 20px;
    font-weight: normal;
  }

#additional-info-overlay .artwork-info{
  display: flex;
  justify-content: center;
}

.artwork-info {
  margin-top: -30px;
}
.image-info{
  margin-left: 65px;
  margin-top: 0px;
  margin-right: 25px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: normal;
}

.image-info h1{
  color: #801818;
  text-align: left;
  margin-bottom: 0px;
  font-size: 28px;
}

.image-info .year{
  color: black;
  display: block;
}

#close-more-button{
  left: 45%;
  position: relative;
  top: 0%;
  border: none;
  margin-top: 25px;
  background: transparent;
  cursor: pointer;
}
#close-more-button:hover{
  cursor: pointer;
}
#close-more-button img{
  width: 75px;
  height: auto;
  background: transparent;
}
#side-info-overlay #close-more-button {
  display: none;
}
#side-info-overlay .compressed_image {
  display: none;
}
.compressed_image {
  width: 60%;
  margin-bottom: 50px;
}
.compressed_image img{
  border: 10px solid black;
  height:auto;
  width: 100%;
}

.logo-icon img{
  width: 40px;
  height: 40px;
}
@media(max-height: 750px) {
  .additional-info-window{
    top: 46%;
  }

  .image-info h1{
    font-size: 26px;
  }

  .art-info-notes {
    font-size: 18px;
    margin-top: 15px;
  }

  .info-block-detail li {
    font-size: 22px;
  }
}
@media (max-width: 1200px) {
  #close-more-button {
    margin-right: 35px;
  }
  .compressed_image {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
  #close-more-button img{
    width: 60px;
  }
  .artwork-info {
    margin-top: 25px;
    font-size: 25px;
  }

  #additional-info-overlay .artwork-info{
    display: flex;
    justify-content: initial;
  }

  .additional-info-window{
    margin-left: 0;
    top: 40%;
  }

  .image-info{
    margin-left: 65px;
    h1{
      font-size: 22px;
    }
    li{
      font-size: 20px;
    }
    .art-info-notes{
      font-size: 16px;
    }
  }
}

@media (max-width: 850px) {
  .additional-info-window{
    top: 30%;
  }
}

@media (max-width: 550px) {

  .compressed_image {
    width: 80%;
    margin-left: 10px;
  }

  .image-info{
    margin-left: 25px;
  }

  #close-more-button img{
    width: 40px;
  }

  #side-info-overlay .additional-info-window{
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 50;
    top: 55%;
  }
  .artwork-info {
    margin-top: 0px;
  }
}
</style>