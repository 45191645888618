import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Catalog from '../views/Catalog.vue';
import ExhibitionsCatalog from '../views/ExhibitionsCatalog';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/artworks/catalog/holland1936',
    props: { name: 'holland1936', country: 'Holland', startYear: '1936', endYear: '1938', },
    component: Catalog,
  },
  {
    path: '/artworks/catalog/sicily1939',
    props: { name: 'sicily1939', country: 'Sicily', startYear: '1939', endYear: '1939', },
    component: Catalog,
  },
  {
    path: '/artworks/catalog/sweden1940',
    props: { name: 'sweden1940', country: 'Sweden', startYear: '1940', endYear: '1948' },
    component: Catalog,
  },
  {
    path: '/artworks/catalog/france1949',
    props: { name: 'france1949', country: 'France', startYear: '1949', endYear: '1949' },
    component: Catalog,
  },
  {
    path: '/artworks/catalog/sweden1950',
    props: { name: 'sweden1950', country: 'Sweden', startYear: '1950', endYear: '1967' },
    component: Catalog,
  },
  {
    path: '/artworks/catalog/holland1969',
    props: { name: 'holland1969', country: 'Holland', startYear: '1969', endYear: '1983' },
    component: Catalog,
  },
  {
    path: '/lostworks',
    name: 'Lostworks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lostworks" */ '../views/Lostworks.vue')
  },
  {
    path: '/biography',
    name: 'Biography',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "biography" */ '../views/Biography.vue')
  },
  {
    path: '/artworks',
    name: 'Artworks',
    component: () => import(/* webpackChunkName: "artworks" */ '../views/Artworks.vue'),
  },
  {
    path: '/exhibitions',
    name: 'Exhibitions',
    component: () => import(/* webpackChunkName: "artworks" */ '../views/Exhibitions.vue'),
  },
  {
    path: '/exhibitions/exhibition/:id',
    component: ExhibitionsCatalog,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
